<div mat-dialog-title class="mat-dialog-title">
  <div>{{ data.actionName }}</div>
  <img mat-dialog-close class="dialog__header-icon" src="assets/icons/icon-close-dark.svg" alt="close icon"
    (click)='close("success")' />
</div>
<form [formGroup]="addAction" autocomplete="off" novalidate (ngSubmit)="submitaction(addAction.value)">
  <mat-dialog-content id="submitAction" class="mat-typography mat-dialog-content">
    <div class="macro-row">
      <reactive-filter-select label="Macros" [options]="macroList" groupBy="macroSubCategoryName"(openedChange)="onMacrosChange()" [forceShowSearch]="true" panelClass="macro-select"
        formControlName="macros" valueType="object" selectType="multiple"></reactive-filter-select>
    </div>
    <div class="notes-row">
      <mat-form-field class="ig2 mat-form-field-no-animation form-width-margin" appearance="fill">
        <mat-label class="mat-label-wrap">Note
        </mat-label>
        <textarea formControlName="notes" id="notes" [maxlength]="3000" matInput cdkTextareaAutosize
          cdkAutosizeMinRows="1" cdkAutosizeMaxRows="1" class="textareaheight"></textarea>
      </mat-form-field>
      <p>{{getCountOfRemainingLetters()}} character is remaining</p>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions class="mat-dialog-actions" align="end">
    <button mat-flat-button mat-dialog-close type="button"
      class="ig2 button button--secondary button--small mat-button mat-button-base btn-padding"
      (click)='close("success")'>Cancel</button>
    <button type="submit" mat-flat-button class="ig2 button button--primary button--small mat-button mat-button-base"
      cdkFocusInitial [disabled]="!addAction.valid">Submit</button>
  </mat-dialog-actions>
</form>
