import { MacroModel, MacroSubCategory } from './../../models/CategoryItem';
import { getUserInfo } from '@igps/client-root-config';
import { Component, HostListener, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActionService } from 'src/app/services/action.service';
import { finalize, Observable, of, switchMap } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MacrosResponse } from 'src/app/models/ActionResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { ReloadTaskBasicInfoEvent } from '@igps/file-info-header';
import { TaskBasicInfo } from 'src/app/models/taskBasicInfo';
import { UtilityService } from 'src/app/services/utility.service';
import { refreshActions } from '../action-section/action-section.component';
import { CommonService } from '../../services/common.service'
import { SourceType } from 'src/app/enums/source-type';
import { CommonMessages } from 'src/app/enums/CommonMessages';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { EmailModel } from 'src/app/models/EmailModel';

@Component({
  selector: 'app-open-dialog',
  templateUrl: './open-dialog.component.html',
  styleUrls: ['./open-dialog.component.scss']
})
export class OpenDialogComponent implements OnInit {
  public addAction!: FormGroup;
  public initalValues: any;
  hasChange!: boolean;
  macroList: MacroModel[] = [];
  macroSubcategoryList: MacroSubCategory[] = [];
  taskid!: string;
  actionid !: number;
  userInfo: any;
  sourceId!: SourceType;

  constructor(public dialogRef: MatDialogRef<OpenDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      actionName: string;
      actionId: number;
      taskInfo: TaskBasicInfo;
      labels: string[];
      size: string;
      align: string;
      config: any;
      processId: string;
    }, private confirmdialog: MatDialog, private actionService: ActionService, private formBuilder: FormBuilder, private utilityservice: UtilityService, private zone: NgZone, private router: Router) {
  }

  ngOnInit() {
    this.utilityservice.setSpinner(true);
    this.taskid = this.data.taskInfo.id;
    this.sourceId = this.data.taskInfo.sourceId;
    this.initializeForm();
    this.actionid = this.data.actionId;
    this.userInfo = getUserInfo();
    const isOnshore: boolean = (this.userInfo?.site?.name as string)?.toLowerCase() === 'onshore' ? true : false;
    this.actionService.getMacroSubCategory(this.data.actionId).subscribe(data => {
      this.utilityservice.setSpinner(false);
      this.macroSubcategoryList = this.macroSubcategoryList.concat(data);
      this.macroList = this.macroList.filter(m => !this.macroSubcategoryList.some(ms => ms.name === m.macroSubCategoryName));
    }, err => {
      this.utilityservice.setSpinner(false);
    });
    this.actionService.getMacro(this.data.actionId, this.data.taskInfo.processId, isOnshore).subscribe(data => {
      this.utilityservice.setSpinner(false);
      this.macroList = this.macroList.concat(data);
      this.macroList = this.macroList.filter(m => !this.macroSubcategoryList.some(ms => ms.name === m.macroSubCategoryName));
    }, err => {
      this.utilityservice.setSpinner(false);
    });
  }

  onMacrosChange() {
    this.addAction.controls['notes'].setValue('');
    if (this.addAction.controls["macros"].value?.length > 0) {
      for (let index = 0; index < this.addAction.controls["macros"].value.length; index++) {
        const item: MacroModel = this.addAction.controls["macros"].value[index] as MacroModel;
        const description = index === 0 ? item.description : `\n${item.description}`;
        this.addAction.controls['notes'].setValue(this.addAction.controls['notes'].value + description);
      }
      this.addAction.controls['notes'].setValue(this.addAction.controls['notes'].value.replace(/\n$/, ''));
    }
  }

  initializeForm() {
    this.addAction = this.formBuilder.group(
      {
        macros: [],
        notes: ['', [Validators.minLength(1), Validators.maxLength(3000), Validators.required]]
      });
    if (this.data.actionName === 'Send to Processor' || this.data.actionName === 'Complete') {
      this.addAction.controls['notes'].clearValidators();
    }
    this.initalValues = this.addAction.value;
    this.addAction.valueChanges.subscribe(() => {
      this.hasChange = Object.keys(this.initalValues).some(key => this.addAction.value[key] !== this.initalValues[key])
    });
  }

  getCountOfRemainingLetters() {
    const controlName = 'notes';
    return (3000 - this.addAction?.controls[controlName]?.value?.length);
  }

  public submitaction = (_macroForm: any) => {
    this.addAction.controls['notes'].setValue(this.addAction.controls['notes'].value.trim());
    if (this.addAction.controls['notes'].value.length === 0 && !(this.data.actionName === 'Send to Processor' || this.data.actionName === 'Complete')) {
      return;
    }
    const macroids = _macroForm.macros ? (_macroForm.macros as MacroModel[]).map(macro => macro.id) : [];
    const addAction: MacrosResponse = {
      MacroIds: macroids,
      Note: _macroForm.notes
    };
    this.zone.run(() => {

      if (this.data.taskInfo.emailId !== null && this.actionid == 14) {  // Confirmation dialog for Unlink Task
        const message = this.sourceId === SourceType.IgnitePS ? CommonMessages.UnlinkTaskForIgnitePSMessage : CommonMessages.UnlinkTaskForIgniteMessage;
        this.dialogRef.close('success');

        const dialogRef2 = this.confirmdialog.open(ConfirmDialogComponent, {
          panelClass: ['ig2', 'mat-dialog-container-small'],
          data: {
            title: 'Confirmation',
            message: message
          },
          disableClose: true
        });
        dialogRef2.afterClosed().subscribe((data) => {
          if (!data) {
            return;
          } else {
            this.submitActionService(addAction);
          }
        });
      }
      else if (this.data.taskInfo.emailId !== null && this.actionid == 10) { // Confirmation dialog for Cancelled Task
        this.dialogRef.close('success');
        const confirmDialog = this.confirmdialog.open(ConfirmDialogComponent, {
          panelClass: ['ig2', 'mat-dialog-container-small'],
          data: {
            title: 'Confirmation',
            message: CommonMessages.CancelledTaskMessage
          },
          maxWidth: '580px',
          disableClose: true
        });

        confirmDialog.afterClosed().subscribe((result: any) => {
          if (!result) {
            return;
          } else {
            this.submitActionService(addAction);
          }
        });
      }
      else {
        this.submitActionService(addAction);
      }
    });

  }
  private submitActionService(addAction: MacrosResponse) {
    this.utilityservice.setSpinner(true);
    this.actionService.submitAction(addAction, this.taskid, this.actionid).pipe(finalize(() => {
      this.utilityservice.setSpinner(false);
      refreshActions.emit();
    }), switchMap((res: any) => {
      if (this.actionid == 14) {
        sessionStorage.setItem('UnLink', 'true');
        return this.actionService.getEmailDetails(this.data.taskInfo.emailId);
      } else {
        sessionStorage.removeItem('UnLink');
      }
      return of(null);
    }))
      .subscribe({
        next: (res) => {
          this.utilityservice.showToast.emit({ message: 'Submitted successfully', isError: false });
          ReloadTaskBasicInfoEvent.emit();
          /// If res is not null and actionId is 14 i.e. 'Unlink Task' then it redirect
          if (res) {

            this.router.navigateByUrl('/email-search', {
              state: {
                emailId: res.id,
                emailStatusId: res.status,
                assigneeId: res.assignedTo,
                onShoreToggle: true // To be change
              }
            });
          }
        },
        error: (error: HttpErrorResponse) => {
          if (error?.error.Status !== 409) {
            this.utilityservice.showToast.emit({ message: 'Error submitting', isError: true });
          } else {
            this.utilityservice.showToast.emit({ message: 'Error occurred', isError: true });
          }
          this.dialogRef.close('error');
        },
        complete: () => {
          this.dialogRef.close('success');
        }
      });
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChange;
  }
  close(message: string): void {
    this.dialogRef.close(message.toLowerCase());
  }


}
