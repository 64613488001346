import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ToastComponent } from '@ui-core/toast';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { ActionOption } from './enums/action-option';
import { ActionItem } from './models/CategoryItem';
import { UtilityService } from './services/utility.service';

@Component({
  selector: 'igps-client-actionspopup',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  toastEventSubscription!: Subscription;
  spinnerSubscription!: Subscription;

  @ViewChild(ToastComponent) toaster!: ToastComponent;
  showSpinner: boolean = false;

  constructor(private utilityService: UtilityService, private cdref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    sessionStorage.removeItem('UnLink');
    this.spinnerSubscription = this.utilityService.showSpinner.subscribe(d => { this.showSpinner = d; this.cdref.detectChanges(); });
    this.toastEventSubscription = this.utilityService.showToast.subscribe(d => { this.openToast(d.message, d.isError); this.cdref.detectChanges(); });
  }

  ngOnDestroy(): void {
    this.toastEventSubscription.unsubscribe();
    this.spinnerSubscription.unsubscribe();
  }

  openToast(message: string, isError: boolean = false) {
    this.toaster.toastMessage = message;
    this.toaster.actionRightText = 'close';
    this.toaster.showToast = true;
    this.toaster.error = isError;
    this.toaster.toasterTimeout();
    this.cdref.detectChanges();
  }


}
